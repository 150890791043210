import { AuthContextProvider, useAuthState } from './firebase';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link

} from 'react-router-dom';
import { Login } from "./login";
import { Home } from "./Home";
import { Suspense } from 'react';

function App() {
  const { isAuthenticated } = useAuthState();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AuthContextProvider>
        <Router>
          <Routes>
            <Route
              exact path="/"
              element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
            />
            <Route
              path="/login"
              element={!isAuthenticated ? <Login /> : <Navigate to="/" />}
            />            

          </Routes>
        </Router>
      </AuthContextProvider>
    </Suspense>
  );
}

export default App;
