import { useCallback } from 'react'
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect, signInWithPopup } from 'firebase/auth'
import "./css/login.css"

export const Login = () => {

  const handleGoogleSignIn = useCallback(async () => {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    try {
      await signInWithPopup(auth, provider)
      console.log('Successfully logged in with Google!')
    } catch (error) {
      console.error('Error signing in with Google:', error.message)
      alert('Error signing in with Google: ' + error.message)
    }
  }, [])

  return (
    
    <>
      
      <body>
        <section class="showcase">
        <div className="video-container">
  <video autoPlay={true} loop={true} playsInline={true} muted={true} controls={false} src="./loginvideo.mp4" type="video/mp4">
  </video>
</div>
        <div class="content">
          <h1>IDIOTS IN GAMES.</h1>
        <button class="google-sign-in-button" onClick={handleGoogleSignIn}>Sign in with Google</button>
        </div>
        </section>
        <section class="about">
          <h3>
          IDIOTS IN GAMES. is a longstanding tradition
          which started all the way back in 2015. For almost 10 years the group has watched these videos
           as a reminder of better times. Those with approved accounts can press the button above to enter the universe. Enjoy.
          </h3>
          <a href="#top">Back to top of page</a>
          <a> Copyright © IDIOTS IN GAMES 2024</a>        
          </section>
      </body>

    </>
  )
}
