import { getAuth, signOut, } from 'firebase/auth'
import { useAuthState } from './firebase'

import {Link} from "react-router-dom"
import './css/navbar.css'
import React, { useState } from 'react';



export const Navbar = () => {
  const { user } = useAuthState();
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      document.getElementById("mySidenav").style.width = "310px";
      document.documentElement.style.overflowY = "hidden";
      document.body.style.backgroundColor = "rgba(0,0,0,0.6)";
      const specifiedElement = document.getElementById('mySidenav')

    } else {
      document.getElementById("mySidenav").style.width = "0";
      document.body.style.backgroundColor = "black";
      document.documentElement.style.overflowY = "";


    }
  };


    return (
      <nav>
          <a className="Logo" href="#top">IDIOTS IN GAMES.</a>
          <ul>
            <li> <a className="button" href="#video_section">VIDEOS </a></li>
            <li> <a className="button" href="#about">ABOUT </a></li>
          </ul>
          <button className="hamburger-button" onClick={toggleMenu}>
        ☰
      </button>


      <div class="sign-out">
      <img src={user?.photoURL} alt="Profile" /> 
      <h4>{user?.displayName}</h4>
      <a className="button" onClick={() => signOut(getAuth())}>SIGN OUT </a>
      </div>

      <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" onClick={toggleMenu}>&times;</a>

      <img src={user?.photoURL} alt="Profile" /> 
      <h4>{user?.displayName}</h4>
      <a href="#video_section"  onClick={toggleMenu}>VIDEOS </a>
    <a href="#about"onClick={toggleMenu}>ABOUT</a>
    <a onClick={() => signOut(getAuth())}>SIGN OUT </a>
    </div>
      </nav>
    )
}