import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { getDatabase } from "firebase/database";


export const firebaseApp = initializeApp({
  apiKey: "AIzaSyBwAdpo97jfQyFNsnysoeHP3o2_xiiKY88",
  authDomain: "iigg-95db7.firebaseapp.com",
  projectId: "iigg-95db7",
  storageBucket: "iigg-95db7.appspot.com",
  messagingSenderId: "422224086949",
  appId: "1:422224086949:web:d9411019c16ee2af1f8a4c",
  databaseURL: "https://iigg-95db7-default-rtdb.europe-west1.firebasedatabase.app",
});

export const AuthContext = createContext()

export const AuthContextProvider = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    return () => unsubscribe()
  }, [])
  return <AuthContext.Provider value={{ user, error }} {...props} />
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return {
     ...auth, isAuthenticated: auth.user != null }
}

const database = getDatabase(firebaseApp);